import axios, { AxiosRequestConfig, AxiosResponse } from "axios"

//Generate a random UUID as an idempotency key for the payment request
// length of idempotency_key should be less than 45
export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    // eslint-disable-next-line no-mixed-operators
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

const HST = 0.15;

class API {
  static instance: API;
  static id: string;

  ACTIVE_STORE_KEY: string = "ACTIVE_STORE";
  store?: string = undefined;
  baseUrl: string = (process.env.REACT_APP_API_HOST as string);
  extendedUrl: string = this.store ? (process.env.REACT_APP_API_HOST as string) + "/" + this.store : '';

  private constructor() {
    const myStore = window.localStorage.getItem(this.ACTIVE_STORE_KEY);

    if (myStore) {
      this.store = myStore;
    }
    this.extendedUrl = (process.env.REACT_APP_API_HOST as string) + "/" + this.store;
    API.id = uuidv4();
  }

  public static getInstance() : API {
    if (!API.instance) {
      API.instance = new API();
    }
    return API.instance;
  }

  public getStore() {
    return this.store;
  }

  idempotency_key = () => {
    return uuidv4();
  }

  getAxiosConfig = (accessToken: any, upload?: boolean) : AxiosRequestConfig => {
    const headers: Record<string,string> = {};

    if (!accessToken) {
      return {};
    }

    if (accessToken) {
      headers['Authorization'] = 'Bearer ' + accessToken;
    }
    if (upload) {
      headers['Content-Type'] ='multipart/form-data';
    }

    return {headers};
  }

  getFetchConfig = (method: string, body: any, accessToken: any): RequestInit => {
    const results: RequestInit = {
      method: method ? method : "GET",
      headers: accessToken ? {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': 'Bearer ' + accessToken
      } : {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: body ? JSON.stringify(body) : undefined
    }

    return results;
  }

  doGet = async(url: string, accessToken: any): Promise<any> => {
    const response = await fetch(url, this.getFetchConfig("GET", undefined, accessToken));
    return response.json();
  }

  doPatch = async(url: string, body: any, accessToken: any): Promise<any> => {
    const response = await fetch(url, this.getFetchConfig("PATCH", body, accessToken));
    return response.json();
  }


  getShortItemName = (name: string): string => {
    if (!name) {
      return 'Unnamed';
    }
    const tokens = name.split(">");
    return tokens[tokens.length - 1].trim();  
  }

  adaptItem = (item: IItem): IItem => {
    // Force UPC value
    const upc = item.codes?.upc ? item.codes.upc : item.sku;
    
    // More adapting
    return {
      ...item,
      name: this.getShortItemName(item.name),
      codes: {
        upc: upc,
        sku: item.codes?.sku
      },
      hidden: item.flags?.hidden,
      discontinued: item.flags?.discontinued,
      special: item.flags?.special
    }
  }


  getItems = async (accessToken?: string): Promise<IItem[]> => {
    try {
      const items: AxiosResponse<IItem[]> = await axios.get(this.extendedUrl + "/api/v1/items", this.getAxiosConfig(accessToken));
      return items.data.map((item) => {
        return this.adaptItem(item);
      });
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  getIncompleteItems = async (accessToken?: string): Promise<IItem[]> => {
    try {
      const items: AxiosResponse<IItem[]> = await axios.get(this.extendedUrl + "/api/v1/items?filter=incomplete", this.getAxiosConfig(accessToken));
      return items.data.map((item) => {
        return this.adaptItem(item);
      });
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  getOrphanedItems = async (accessToken?: string): Promise<IItem[]> => {
    try {
      const items: AxiosResponse<IItem[]> = await axios.get(this.extendedUrl + "/api/v1/items?filter=orphans", this.getAxiosConfig(accessToken));
      return items.data.map((item) => {
        return this.adaptItem(item);
      });
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  getItemsByCategory = async (categoryId: string, accessToken?: string): Promise<IItem[]> => {
    try {
      const items: AxiosResponse<IItem[]> = await axios.get(this.extendedUrl + "/api/v1/items/category/" + categoryId, this.getAxiosConfig(accessToken));
      return items.data.map((item) => {
        return this.adaptItem(item);
      });
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  searchAllItems = async (text: string, accessToken?: string): Promise<IItem[]> => {
    if (!text) {
      return [];
    }
    try {
      const items: AxiosResponse<IItem[]> = await axios.get(this.extendedUrl + "/api/v1/items/search/all/" + text, this.getAxiosConfig(accessToken));
      return items.data.map((item) => {
        return this.adaptItem(item);
      });
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  searchItems = async (text: string, accessToken?: string): Promise<IItem[]> => {
    if (!text) {
      return [];
    }
    try {
      const items: AxiosResponse<IItem[]> = await axios.get(this.extendedUrl + "/api/v1/items/search/items/" + text, this.getAxiosConfig(accessToken));
      return items.data.map((item) => {
        return this.adaptItem(item);
      });
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  searchItemsByNameAndPrice = async (text: string, price: number, accessToken?: string): Promise<IItem[]> => {
    try {
      const items: AxiosResponse<IItem[]> = await axios.get(`${this.extendedUrl}/api/v1/items/search/${text}/price/${price}`, this.getAxiosConfig(accessToken));
      return items.data.map((item) => {
        return this.adaptItem(item);
      });
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  getItemById = async (itemId: string, accessToken?: string): Promise<IItem> => {
    try {
      const item: AxiosResponse<IItem> = await axios.get(this.extendedUrl + "/api/v1/items/" + itemId, this.getAxiosConfig(accessToken));
      // const item = await doGet(baseUrl + "/api/v1/items/" + itemId, accessToken);
      return this.adaptItem(item.data);
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  getItemByExternalId = async (vendor: string, externalId: string, accessToken?: string): Promise<IItem> => {
    console.log("GET BY EID: " + vendor + " " + externalId);
    try {
      // Use the baseUrl, because we might resolve to ANY of the three databases
      const item: AxiosResponse<IItem> = await axios.get(this.baseUrl + "/api/v1/items/vendor/" + vendor + "/" + externalId, this.getAxiosConfig(accessToken));
      return this.adaptItem(item.data);
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  getVariantsById = async (itemId: string, accessToken?: string): Promise<IItem[]> => {
    try {
      const items: AxiosResponse<IItem[]> = await axios.get(this.extendedUrl + "/api/v1/items/" + itemId + "/variants", this.getAxiosConfig(accessToken));
      return items.data.map((item) => {
        return this.adaptItem(item);
      });
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  getImagesById = async (imageId: string): Promise<IImage[]> => {
    try {
      const items: AxiosResponse<IImage[]> = await axios.get(this.extendedUrl + "/api/v1/images/" + imageId);
      return items.data;
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  findCategoryByName = (cats: ICategory[], name: string): ICategory | undefined => {
    for (let i = 0; i < cats.length; i++) {
      const cat = cats[i];
      if (cat.name === name) {
        return cat;
      }
    }
    return undefined;
  }

  categorySort = (cat1: ICategory, cat2: ICategory): number => {
    return cat1.shortName < cat2.shortName ? -1 : cat1.shortName > cat2.shortName ? 1 : 0;
  }

  getShortCategoryName = (name: string) => {
    // const tokens = name.split('&');
    // const shortName = tokens[0].trim();
    // switch (shortName) {
    //   case 'Growing Kits': return 'Kits';
    //   case 'Soils': return 'Media';
    //   case 'Fertilizers': return 'Nutrients';
    //   case 'Climate Control': return 'Climate';
    //   case 'Odour Control': return 'Odour';
    //   default: return shortName;
    // }
    return name;
  }

  getCategoryById = async (id: string): Promise<ICategory | undefined> => {
    try {
      const categories: AxiosResponse<ICategory[]> = await axios.get(this.extendedUrl + "/api/v1/categories");

      for (let i = 0; i < categories.data.length; i++) {
        const category = categories.data[i];
        if (category.id === id) {
          return category;
        }
      }

      return undefined;
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  getCategoryPathById = async (id: string): Promise<ICategory[]> => {
    try {
      const categories = await this.getCategories();

      for (let i = 0; i < categories.length; i++) {
        const path = this.doGetCategoryPath(id, categories[i]);
        if (path.length > 0) {
          return path;
        }
      }

      return [];
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  doGetCategoryPath = (id: string, category: ICategory): ICategory[] => {
    if (category.id === id) {
      return [category];
    }
    if (category.name === id) {
      return [category];
    }
    if (category.children) {
      for (let i = 0; i < category.children.length; i++) {
        const child = category.children[i];
        const path = this.doGetCategoryPath(id, child);
        if (path.length > 0) {
          return [category, ...path];
        }
      }
    }
    return [];
  }

  setActiveStore = async (name?: string) => {
    this.store = name ? name : "backroom";
    this.extendedUrl = (process.env.REACT_APP_API_HOST as string) + "/" + this.store;

    window.localStorage.setItem(this.ACTIVE_STORE_KEY, this.store);
  }

  /**
   * Get the category tree
   */
  getCategories = async (showHidden?: boolean): Promise<ICategory[]> => {
    try {
      const categories: AxiosResponse<ICategory[]> = await axios.get(this.extendedUrl + "/api/v1/categories");

      // Make a lookup table of id to category
      const lookup: Record<string,ICategory> = {};
      for (let i = 0; i < categories.data.length; i++) {
        const category = categories.data[i];
        if (!category.children) {
          category.children = [];
        }
        lookup[category.id] = category;
      }

      // Connect children
      const roots: Record<string,ICategory> = {};
      for (let i = 0; i < categories.data.length; i++) {
        const category = categories.data[i];
        if (category.parentId !== "1") {
          const parent = lookup[category.parentId];
          if (!parent) {
            continue;
          }
          parent.children.push(category);
        }
        else {
          roots[category.id] = category;
        }
      }

      // Leaves don't have items and are marked specially
      for (let i = 0; i < categories.data.length; i++) {
        const category = categories.data[i];

        category.shortName = category.name;
        category.url = '/category/' + category.id;

        // Ignore the hidden folder unless we are explicitly told to
        if (!showHidden && category.shortName === "Hidden") {
          continue;
        }
      }

      const results = Object.values(roots);
      results.sort(this.categorySort);
      for (let i = 0; i < results.length; i++) {
        const category = results[i];
        category.children.sort(this.categorySort);
      }

      // return results[0].children;
      return results;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  /**
   * Get the configuration from the server
   */
  getServerConfig = async (): Promise<IConfig> => {
    try {
      const config: AxiosResponse<IConfig> = await axios.get(this.extendedUrl + "/api/v1/config");
      return config.data;
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  getOrderById = async (dbName: string, id: string, accessToken?: string): Promise<IOrder|undefined> => {
    try {
      console.log(`FETCH: ${this.baseUrl}/${dbName}/api/v1/order/${id}`);
      const result = await axios.get(`${this.baseUrl}/${dbName}/api/v1/order/${id}`, this.getAxiosConfig(accessToken));
      if (result.status === 200) {
        return result.data;
      }
    } catch (error: any) {
      throw new Error(JSON.stringify(error.response.data));
    }
  }

  setOrderStateById = async(dbName: string, orderId: string, state: string, accessToken?: string): Promise<void> => {
    try {
      const result = await axios.get(`${this.baseUrl}/${dbName}/api/v1/order/${orderId}/state/${state}`, this.getAxiosConfig(accessToken));
      if (result.status === 200) {
        return result.data;
      }
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  /**
   * getOrders is one of the few APIs that use the baseUrl, because it returns results from ALL databases.
   */
  getOrders = async (accessToken?: string, days?: number): Promise<IOrder[]|undefined> => {
    try {
      const result = days ?
        await axios.get(`${this.baseUrl}/api/v1/order/${days}`, this.getAxiosConfig(accessToken)) :
        await axios.get(`${this.baseUrl}/api/v1/order`, this.getAxiosConfig(accessToken));
      if (result.status === 200) {
        return result.data;
      }
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  getAllOrderDetails = async (accessToken?: string): Promise<IOrder[]|undefined> => {
    const cutoff = new Date("2023-01-01");
    try {
      const result = await axios.get(`${this.baseUrl}/api/v1/order/details`, this.getAxiosConfig(accessToken));
      const orderDetails: IOrder[] = [];
      if (result.status === 200) {
        const orders = result.data;
        // Loop through all the orders calculating the total and taxes
        for (let i = 0; i < orders.length; i++) {
          const order = orders[i];
          // FIXME: For audit we only care about 2022 and earlier
          const orderDate = new Date(order.created_at);
          if (orderDate >= cutoff) {
            continue;
          }
          switch (order.state) {
            case "PAID":
            case "SHIPPED":
            case "DELIVERED":
            case "CLOSED":
              break;
            default:
              continue;
          }
          order.total = 0;
          order.subtotal = 0;
          order.tax = {value: 0};
          for (let j = 0; j < order.items.length; j++) {
            const item = order.items[j];
            order.subtotal += item.price;
            order.tax.value += Math.round(item.price * HST);
          }
          order.total = order.subtotal + order.tax.value;
          orderDetails.push(order);
        }
        return orderDetails;
      }
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  getDiscount = async (discountId: string): Promise<IDiscount|undefined> => {
    try {
      const result = await axios.get(`${this.extendedUrl}/api/v1/order/discount/${discountId}`);
      if (result.status === 200) {
        return result.data;
      }
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  processPayment = async(nonce: string): Promise<Record<string, string>> => {
    try {
      const body = {
        nonce: nonce,
        idempotency_key: this.idempotency_key(),
        location_id: "REPLACE_WITH_LOCATION_ID"
      }

      const result = await axios.post(this.extendedUrl + "/api/v1/payment/process", body);
      return result.data;
    } catch (error: any) {
      console.log("ERROR: " + error);
      if (error.response) {
        throw new Error(error.response.data);
      }
      else {
        throw error;
      }
    }
  }

  cloneImage = async (itemId: string, imageUrl: string, accessToken?: string): Promise<boolean> => {
    var formData: Record<string, string> = {};
    formData['imageUrl'] = imageUrl;
    try {
      await axios.patch(this.extendedUrl + "/api/v1/items/" + itemId + "/image", formData, this.getAxiosConfig(accessToken));
      return true;
    } catch (error: any) {
      console.log(error.message);
      return false;
    }
  }

  dataURLtoFile = (dataurl: any, filename: any) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n) {
      u8arr[n - 1] = bstr.charCodeAt(n - 1)
      n -= 1 // to make eslint happy
    }
    return new File([u8arr], filename, { type: mime })
  }

  uploadImage = async (itemId: string, file: any, accessToken?: string): Promise<boolean> => {
    console.log("FILE: " + itemId + " " +  JSON.stringify(file));
    // generate file from base64 string
    const fileObject = this.dataURLtoFile(file.path, file.name);

    var formData = new FormData();
    formData.append('file', fileObject, file.name);

    try {
      await axios.post(
        this.extendedUrl + "/api/v1/items/" + itemId + "/image",
        formData,
        this.getAxiosConfig(accessToken, true));
      return true;
    } catch (error: any) {
      console.log(error.message);
      return false;
    }
  }

  updateItemField = async (itemId: string, field: string, value: string | number | boolean | Record<any, any> | undefined, accessToken?: string): Promise<boolean> => {
    var formData: Record<string, string | number | boolean | number[] | undefined> = {};
    switch (typeof value) {
      case "string":
      case "number":
      case "boolean":
        formData[field] = value;
        break;
      default:
        formData[field] = JSON.stringify(value) ;
        break;
    }
    try {
      await axios.patch(this.extendedUrl + "/api/v1/items/" + itemId + "/" + field, formData, this.getAxiosConfig(accessToken));
      // await doPatch(baseUrl + "/api/v1/items/" + itemId, formData, accessToken);
      return true;
    } catch (error: any) {
      throw error?.response?.data ? error.response.data : error;
    }
  }

  getRoles = async (accessToken?: string): Promise<string[]> => {
    try {
      const roles: AxiosResponse<string[]> = await axios.get(this.extendedUrl + "/api/v1/user/roles", this.getAxiosConfig(accessToken));
      return roles.data;
    } catch (error: any) {
      throw new Error(error.response.data);
    }  
  }

  createItem = async (item: IItem, accessToken?: string): Promise<IItem> => {
    var formData: Record<string, string> = {};
    formData['item'] = JSON.stringify(item);
    try {
      const response = await axios.put(this.extendedUrl + "/api/v1/items", formData, this.getAxiosConfig(accessToken));
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  deleteItem = async (item: IItem, accessToken?: string): Promise<IItem> => {
    var formData: Record<string, string> = {};
    formData['item'] = JSON.stringify(item);
    try {
      const response = await axios.delete(`${this.extendedUrl}/api/v1/items/${item.id}`, this.getAxiosConfig(accessToken));
      return response.data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  syncItem = async (item: IItem, accessToken?: string): Promise<IItem> => {
    try {
      const response = await axios.get(`${this.extendedUrl}/api/v1/db/sync/${item.id}`, this.getAxiosConfig(accessToken));
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  createCategory = async (path: string, name: string, accessToken?: string): Promise<ICategory> => {
    var formData: Record<string, string> = {};
    formData['path'] = path;
    formData['name'] = name;
    try {
      const response = await axios.put(this.extendedUrl + "/api/v1/categories", formData, this.getAxiosConfig(accessToken));
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  renameCategory = async (id: string, name: string, accessToken?: string): Promise<ICategory> => {
    var formData: Record<string, string> = {};
    formData['id'] = id;
    formData['name'] = name;
    try {
      const response = await axios.put(`${this.extendedUrl}/api/v1/categories/${id}`, formData, this.getAxiosConfig(accessToken));
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  deleteCategory = async (id: string, accessToken?: string): Promise<ICategory> => {
    try {
      const response = await axios.delete(`${this.extendedUrl}/api/v1/categories/${id}`, this.getAxiosConfig(accessToken));
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  reorderCategory = async (id: string, direction: number, accessToken?: string): Promise<ICategory> => {
    try {
      const response = await axios.post(`${this.extendedUrl}/api/v1/categories/${id}/move/${direction}`, this.getAxiosConfig(accessToken));
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  moveCategory = async (childId: string, parentId: string, accessToken?: string): Promise<ICategory> => {
    var formData: Record<string, string> = {};
    formData['childId'] = childId;
    formData['parentId'] = parentId;
    try {
      const response = await axios.put(`${this.extendedUrl}/api/v1/categories/${formData['childId']}/${formData['parentId']}`, formData, this.getAxiosConfig(accessToken));
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  getClientConfig = async (name: string, accessToken?: string): Promise<Record<string, Object>> => {
    try {
      const roles: AxiosResponse<Record<string, Object>> = await axios.get(this.extendedUrl + "/api/v1/config/" + name, this.getAxiosConfig(accessToken));
      return roles.data;
    } catch (error: any) {
      throw new Error(error.response.data);
    }  

  }

  /**
   * Call this function to send a payment token, buyer name, and other details
   * to the project server code so that a payment can be created with 
   * Payments API
   * 
   * https://developer.squareup.com/docs/web-payments/take-card-payment
   */
   createPayment = async (
     provider: string,
     locationId: string,
     environment: string,
     token: string,
     orderId: string,
     accessToken?: string): Promise<any> => {
    const body = {
      provider: provider,
      locationId: locationId,
      environment: environment,
      token: token,
      orderId: orderId,
      idempotency_key: uuidv4()
    };

    // const paymentResponse = await fetch(`${this.extendedUrl}/api/v1/payment`, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body,
    // });

    // if (paymentResponse.ok) {
    //   return paymentResponse.json();
    // }

    // throw paymentResponse;
    try {
      const response = await axios.post(`${this.extendedUrl}/api/v1/payment`, body, this.getAxiosConfig(accessToken));
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  
  }

  /**
   * 
   * @param from 
   * @param until 
   * @param accessToken 
   * @returns 
   */
  getTransactions = async (from?: Date, until?: Date, accessToken?: string): Promise<ITransaction[]|undefined> => {
    try {
      const result = await axios.get(`${this.baseUrl}/api/v1/transactions/${from?.valueOf()}/${until?.valueOf()}`, this.getAxiosConfig(accessToken));
      if (result.status === 200) {
        for (let i = 0; i < result.data.length; i++) {
          result.data[i].created_at = new Date(result.data[i].created_at);
          result.data[i].modified_at = new Date(result.data[i].modified_at);
        }
        return result.data;
      }
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  getYearlyTransactionSummary = async (location: string, accessToken?: string): Promise<any> => {
    const now = new Date();
    try {
      const result = await axios.get(`${this.baseUrl}/api/v1/transactions/summary/${location}/years?timestamp${now.getTime()}`, this.getAxiosConfig(accessToken));
      if (result.status === 200) {
        return result.data;
      }
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  getMonthlyTransactionSummary = async (location: string, year: number, accessToken?: string): Promise<any> => {
    const now = new Date();
    try {
      const result = await axios.get(`${this.baseUrl}/api/v1/transactions/summary/${location}/${year}/months?timestamp${now.getTime()}`, this.getAxiosConfig(accessToken));
      if (result.status === 200) {
        return result.data;
      }
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  getWeeklyTransactionSummary = async (location: string, year: number, accessToken?: string): Promise<any> => {
    const now = new Date();
    try {
      const result = await axios.get(`${this.baseUrl}/api/v1/transactions/summary/${location}/${year}/weeks?timestamp${now.getTime()}`, this.getAxiosConfig(accessToken));
      if (result.status === 200) {
        return result.data;
      }
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  getDailyTransactionSummary = async (location: string, year: number, accessToken?: string): Promise<any> => {
    const now = new Date();
    try {
      const result = await axios.get(`${this.baseUrl}/api/v1/transactions/summary/${location}/${year}/daily?timestamp${now.getTime()}`, this.getAxiosConfig(accessToken));
      if (result.status === 200) {
        return result.data;
      }
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  getTransactionsHourlyRollup = async (location: string, from: Date, until: Date, accessToken?: string): Promise<any> => {
    const now = new Date();
    try {
      const result = await axios.get(`${this.baseUrl}/api/v1/transactions/hourly/${location}/1/2?timestamp${now.getTime()}`, this.getAxiosConfig(accessToken));
      if (result.status === 200) {
        return result.data;
      }
    } catch (error: any) {
      throw new Error(error.response.data);
    }
  }

  
  reconcileTransaction = async (
    vendor: string,
    location: string,
    txnId: string,
    item: LineItem,
    accessToken?: string): Promise<LineItem> => {
    try {
      const result = await axios.patch(`${this.baseUrl}/api/v1/transactions/${vendor}/${location}/${txnId}/lineItem/${item.id}`, item, this.getAxiosConfig(accessToken));
      return result.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
}

export default API.getInstance();